import '../asset/css/index.css'
import 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import StickyBox from "react-sticky-box";
import { Tabs, Tab } from "react-bootstrap";
import React from 'react';
import { Alert } from "antd";
// import DayScreen from './DayScreen';
import DayScreen from './ErrorDayScreen';
import Config from '../util/Config.json';
import Topbar from '../components/topbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import ErrorSummaryScreen from '../views/ErrorSummaryScreen';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: { status: ["Active"], region: [], country: [], siteName: [] },
      flags: Config.FLAGS,
      monthSelected: new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2),
      facetsLoading: Config.FACETS_LOADING,
      grid: Config.GRID,
      selectedTab: 'month'
    };
  }

  componentDidMount() {
    this.applyURLParams();
  }

  onUpdateFlags = (flags)=>{
    this.setState({flags : flags})
  }

  applyURLParams = () => {
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;
    let region = searchParams.get('region');
    let countries = searchParams.get('countries');
    let siteNames = searchParams.get('sitename');
    let status = searchParams.get('status');
    let regionsList = [];
    let countryList = [];
    let siteNameList = [];
    let statusList = [];
    if (region) {
      regionsList = region.split(',');
    }
    if (countries) {
      countryList = countries.split(',');
    }
    if (siteNames) {
      siteNameList = siteNames.split(',');
    }

    if (status != null) {
      statusList = status.split(',');
    } else {
      statusList = ['Active'];
    }

    let dataToBeUpdated = {};

    if (regionsList.length)
      dataToBeUpdated['region'] = regionsList;
    else
      dataToBeUpdated['region'] = [];

    if (countryList.length)
      dataToBeUpdated['country'] = countryList;
    else
      dataToBeUpdated['country'] = [];

    if (siteNameList.length)
      dataToBeUpdated['siteName'] = siteNameList;
    else
      dataToBeUpdated['siteName'] = [];

    if (statusList.length)
      dataToBeUpdated['status'] = statusList;
    else
      dataToBeUpdated['status'] = [];

    // this.setState({selectedCountry: dataToBeUpdated.country, selectedRegion: dataToBeUpdated.region, selectedSiteName: dataToBeUpdated.siteName, selectedStatus: dataToBeUpdated.status, activeKeyList: activeKeyList });
    this.setState({ filterData: dataToBeUpdated });
  }
  onExpandAndCollapse = (value) => {
    this.setState({
      grid: value
    });
  }

  onFilterChange = (value, isResetFilter) => {
    let url = new URL(window.location.href);
    if (url.searchParams.get('region') != null || url.searchParams.get('region'))
      url.searchParams.delete('region')

    if (url.searchParams.get('countries') != null || url.searchParams.get('countries'))
      url.searchParams.delete('countries')

    if (url.searchParams.get('sitename') != null || url.searchParams.get('sitename'))
      url.searchParams.delete('sitename')

    if (url.searchParams.get('status') != null || url.searchParams.get('status'))
      url.searchParams.delete('status')

    if (url.searchParams.get('activeKeyList') != null || url.searchParams.get('activeKeyList'))
      url.searchParams.delete('activeKeyList')

    url.searchParams.append('region', value.region.join(','));
    url.searchParams.append('countries', value.country.join(','));
    url.searchParams.append('sitename', value.siteName.join(','));
    url.searchParams.append('status', value.status.join(','));
    url.searchParams.append('activeKeyList', value.activeKeyList.join(','))
    window.history.pushState(null, null, url.href);
    // window.location.href = url.href;

    if(isResetFilter){
      let resetFlags = this.state.flags.map(item =>{
        if(item.selected){
          item.selected = false
        }
        return item;
      } )
      this.setState({ filterData: value, flags : resetFlags });
    }else{
      this.setState({ filterData: value });
    }
    
  }

  onMonthChange = (value) => {
    this.setState({ monthSelected: value });
  }

  handleSelect = async (eventKey) => {
    this.setState({ selectedTab: eventKey });
  }

  render() {
    const { key, errorInCurrentAPI, errorInFilterAPI } = this.props;
    const grid = {
      sm: 12,
      md: 9,
      lg: 9,
      className: ''
    }

    return (
      <React.Fragment>

        <Container fluid>

          <Row>
            <Col sm={12} md={12} lg={12}><Topbar /></Col>

          </Row>
          <Row className="wrapper">
            <Col sm={12} md={12} lg={12}>

              <Container fluid>
                <Row>
                  <Col className="no-padding " sm={12} md={3} lg={3}>
                    <StickyBox ><Sidebar onFilterChange={this.onFilterChange} id='region' onExpandAndCollapse={this.onExpandAndCollapse} /></StickyBox>
                  </Col>
                  <Col className={this.state.grid.className} sm={this.state.grid.sm} md={grid.md} lg={this.state.grid.lg}>
                    {
                      errorInCurrentAPI || errorInFilterAPI ? <Alert style={{ marginBottom: "10px" }} message={errorInCurrentAPI || errorInFilterAPI} type="error" /> : null
                    }
                    <div className="card-container" >
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={this.handleSelect}
                      >
                        {/* <Tab eventKey="month" title="Month">
                          {this.state.selectedTab === 'month' ? <MonthScreen crossorigin filterData={this.state.filterData} onMonthChange={this.onMonthChange} selectedMonth={this.state.monthSelected} /> : ''}

                        </Tab> */}
                        <Tab eventKey="month" title="Month">
                          {this.state.selectedTab === 'month' ? <ErrorSummaryScreen crossorigin filterData={this.state.filterData} onMonthChange={this.onMonthChange} selectedMonth={this.state.monthSelected} /> : ''}

                        </Tab>
                        <Tab eventKey="day" title="Day">
                          {this.state.selectedTab === 'day' ? <DayScreen filterData={this.state.filterData} flags={this.state.flags} onUpdateFlags={this.onUpdateFlags} /> : ''}

                        </Tab>
                        
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}><Footer /></Col>
          </Row>
        </Container>
        )
      </React.Fragment>
    )
  }
}

export default Layout;
